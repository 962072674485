"use client";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

interface Tool {
  name: string;
  href: string;
  icon: React.ReactNode;
  description: string;
  badge?: "free";
}

interface AdditionalToolsProps {
  links: Tool[];
  title: string;
}

export function AdditionalTools({ links, title }: AdditionalToolsProps) {
  const [showAll, setShowAll] = useState(false);
  const linksAlwaysShown = 12;
  const displayedLinks = showAll ? links : links.slice(0, linksAlwaysShown);

  return (
    <div>
      <h3 className="mb-4 text-lg font-semibold text-neutral-50">{title}</h3>
      <div className="grid grid-cols-4 gap-2">
        {displayedLinks.map((item) => (
          <a
            key={item.name}
            href={item.href}
            className="block max-w-64 rounded-2xl border border-slate-800"
          >
            <div className="relative flex items-center gap-2 overflow-hidden rounded-lg p-2 hover:bg-slate-800/50">
              <div className="flex size-10 flex-none items-center justify-center rounded-lg bg-slate-800 group-hover:bg-slate-700">
                {item.icon}
              </div>
              <div>
                <h3 className="text-sm leading-tight text-neutral-50">{item.name}</h3>
                {item.badge && (
                  <span className="absolute right-0 top-0 rounded-bl-lg bg-primary px-2 py-0.5 text-xs capitalize text-white">
                    {item.badge}
                  </span>
                )}
              </div>
            </div>
          </a>
        ))}
      </div>
      <div className="mt-4 flex justify-start">
        {links.length > linksAlwaysShown && !showAll && (
          <button
            onClick={() => setShowAll(true)}
            className="btn btn-ghost btn-sm pl-0 text-slate-300"
          >
            <ChevronDownIcon className="size-6" /> More
          </button>
        )}
        {showAll && (
          <button
            onClick={() => setShowAll(false)}
            className="btn btn-ghost btn-sm pl-0 text-slate-300"
          >
            <ChevronUpIcon className="size-6" /> Less
          </button>
        )}
      </div>
    </div>
  );
}
